import {lazy} from 'react';
import MinimalLayout from "@/layouts/MinimalLayout";
//import AuthGuard from "@/routes/AuthGuard";
//import HomePage from "@/containers/Home/Home";
import DeliveryDetailsContainer from "@/containers/Delivery/DeliveryDetails";
import DeliveryRouteContainer from "@/containers/Delivery/DeliveryRoute";
import DeliveryDocumentContainer from "@/containers/Delivery/DeliveryDocument";
import HelpContainer from "@/containers/Delivery/Help";

const MainRoutes = {
    path: "/",
    element: <MinimalLayout/>,
    children: [
        {path: "/", element: <>Hello alex</>},
        {path: "/delivery/:secretCode/view", element: <DeliveryDetailsContainer/>},
        {path: "/delivery/:secretCode/route", element: <DeliveryRouteContainer/>},
        {path: "/delivery/:secretCode/invoice/:InvoiceRef", element: <DeliveryDocumentContainer/>},
        {path: "/delivery/:secretCode/help", element: <HelpContainer/>},
    ]
}
export default MainRoutes;
