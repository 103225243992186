import {SnackbarProvider} from "notistack";
import Routes from "@/routes";

const AppContainer = () => {
    return (
        <SnackbarProvider maxSnack={3}>
            <Routes/>
        </SnackbarProvider>
    )
}

export default AppContainer
