// @ts-ignore
import ThemeCustomization from './themes';
import AppContainer from "@/containers/App/App";
import {useNavigate} from "react-router-dom";
//import LoadingComponent from "@/components/Common/LoadingComponent";


const App = () => {
    const navigate = useNavigate()
    return (
        <ThemeCustomization>
            <AppContainer/>
        </ThemeCustomization>
    )
}

export default App
