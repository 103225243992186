import React, { useState } from 'react';
import {useParams} from 'react-router-dom';

import {useFormik} from 'formik';
import * as yup from 'yup';
//import SignatureCanvas from 'react-signature-canvas'
import {enqueueSnackbar} from "notistack";

import {Button, Box, Grid, TextField} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { invoiceService } from "@/services/api.service";



const InvoiceSign = (props: any) => {
	const { disabled, InvoiceRef, onSigned } = props;
	const { secretCode = "" } = useParams();
	const [open, setOpen] = useState(false);
	const [isFetching, setFetching] = useState(false);
	
	const handleOpenDialog = () => setOpen(true);
	const handleCloseDialog = () => {
		formik.resetForm();
		setOpen(false);
	}
	
	const validationSchema = yup.object({
		"firstName": yup.string().required("Name is required"),
		"lastName": yup.string().required("Surname is required"),
		"id": yup.string().required("ID is required"),
	});
	
	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			id: ""
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			await submitData(values);
		},
	});

	
	const submitData = async (payload: any) => {
		setFetching(true);
		
		try{
			const response = await invoiceService.sign(InvoiceRef, payload, {secret: secretCode});
			await onSigned(secretCode);
			handleCloseDialog();
			setFetching(false);
			if (response.data.ok) {
				enqueueSnackbar('Invoice has been signed', {variant: 'success'})
			} else {
				enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
			}
		}catch(e) {
			console.error(e)
			enqueueSnackbar('Error: Unknown message', {variant: 'error'})
		}

	}
	
	
	return (
		<>
			<Button variant="contained" onClick={handleOpenDialog} disabled={disabled}>Sign</Button>
			<Dialog open={open} onClose={handleCloseDialog} fullScreen={true}>
				<DialogContent>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									disabled={isFetching}
									fullWidth
									name="id"
									label="מספר תעודת זהות"
									variant="filled"
									value={formik.values["id"]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched["id"] && Boolean(formik.errors["id"])}
									helperText={formik.touched["id"] && formik.errors["id"]}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									disabled={isFetching}
									fullWidth
									name="firstName"
									label="שם פרטי"
									variant="filled"
									value={formik.values["firstName"]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched["firstName"] && Boolean(formik.errors["firstName"])}
									helperText={formik.touched["firstName"] && formik.errors["firstName"]}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									disabled={isFetching}
									fullWidth
									name="lastName"
									label="שם משפחה"
									variant="filled"
									value={formik.values["lastName"]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched["lastName"] && Boolean(formik.errors["lastName"])}
									helperText={formik.touched["lastName"] && formik.errors["lastName"]}
								/>
							</Grid>
							<Grid item xs={12}>
								{/*<SignatureCanvas backgroundColor="#f2f2f2" penColor="#1677ff" canvasProps={{width: 300, height: 300, className: 'sigCanvas'}} />*/}
							</Grid>
						</Grid>
					</form>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={() => formik.handleSubmit()} disabled={isFetching}>Sign invoice</Button>
					<Box flexGrow={1}/>
					<Button onClick={handleCloseDialog}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default InvoiceSign;