import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const DeliveryHeader = () => {
	return (
		<AppBar component="nav">
			<Toolbar>
				<Typography variant="h3">
					אפליקציה ניידת למשלוח
				</Typography>
			</Toolbar>
		</AppBar>
	)
}

export default DeliveryHeader;