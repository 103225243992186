import {Outlet} from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';

import Menu from './Menu';
import Header from './Header';

const MinimalLayout = () => (
    <>
        <Header/>
        <Toolbar/>
        <Outlet/>
        <Toolbar/>
        <Menu/>
    </>
);

export default MinimalLayout;
