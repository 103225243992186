import { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import {Typography, Button, Box} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { invoiceService } from "@/services/api.service";
import InvoiceViewer from "@/components/DeliveryDocument/InvoiceViewer";
import InvoiceSign from "@/components/DeliveryDocument/Dialog/InvoiceSign";
import {enqueueSnackbar} from "notistack";




const ShowField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography align="right" variant="body1" color="textSecondary">{title}</Typography>
			<Typography align="right" variant="body1">{value || "-"}</Typography>
		</div>
		)
}


const InvoiceViewerModal = (props: any) => {
	const { data, onSigned } = props;
	const { secretCode = "" } = useParams();
	const [open, setOpen] = useState(false);
	const [isFetching, setFetching] = useState<boolean>(false);
	const [presignedUrl, setPresignedUrl] = useState<string>("");
	
	useEffect(() => {
		if (!open) return;
		fetchData();
	}, [open])
	
	const fetchData = async () => {
		setFetching(true);
		const response = await invoiceService.getLink(data._id, { secret: secretCode });
		if (response.data.ok) {
			setPresignedUrl(response.data.data);
			setFetching(false);
		} else {
			enqueueSnackbar('Error: ' + response.data.message || "Unknown message", {variant: 'error'})
		}
	}
	
	const handleCloseDialog = () => setOpen(false)
	
	return (
		<>
	 		<Button variant="contained" size="large" onClick={()=>setOpen(true)}>View Invoice</Button>
			<Dialog open={open} onClose={handleCloseDialog} fullScreen={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseDialog}
							aria-label="close"
							>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					{presignedUrl && <InvoiceViewer fileUrl={presignedUrl}/>}
				</DialogContent>
				<DialogActions>
					<InvoiceSign disabled={data?.status!=="ontheway"} InvoiceRef={data?._id} onSigned={onSigned}/>
					&nbsp;&nbsp;
					<Button variant="contained" onClick={handleCloseDialog} disabled={data?.status!=="ontheway"}>Take photo</Button>
					<Box flexGrow={1}/>
					<Button onClick={handleCloseDialog}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
		)
}

export default InvoiceViewerModal;