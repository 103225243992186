import {useRoutes} from "react-router-dom";

import MainRoutes from "./MainRoutes";
//import AuthRoutes from "./AuthRoutes";
//import ErrorRoutes from './ErrorRoutes';

export default function Routes() {
    return useRoutes([
        MainRoutes,
//        AuthRoutes,
//        ErrorRoutes
    ]);
}
