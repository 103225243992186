import {Alert, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography} from "@mui/material";

const ShowField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography align="right" variant="body1" color="textSecondary">{title}</Typography>
			<Typography align="right" variant="body1">{value || "-"}</Typography>
		</div>
		)
}


const DeliveryDetails = (props: any) => {
	const { data } = props;
	return (
		<Card elevation={0} variant="outlined">
			<CardHeader title="מידע"/>
			<Divider/>
			<CardContent>
				<List>
					<ListItem>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<ShowField title="סטטוס" value={data.status}/>
							</Grid>
							<Grid item xs={6}>
								<ShowField title="תאריך אספקה" value={new Date(data.deliveryDate).toLocaleDateString()}/>
							</Grid>
							<Grid item xs={12}>
								<ShowField title="שליח" value={data.Courier.name}/>
							</Grid>
							<Grid item xs={12}>
								<ShowField title="הערות משרד אחורי" value={data.comment}/>
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	)
}

export default DeliveryDetails;