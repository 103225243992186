import {Grid} from '@mui/material';

const HelpContainer = () => {
    return (
        <> 
            <Grid container>
                Help or FAQ text here
            </Grid>
        </>
    )
}

export default HelpContainer
