import {useEffect, useState} from 'react';
import {useParams,useNavigate} from 'react-router-dom';
import {Alert, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography, ListItemText, IconButton, ListItemIcon} from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import { customerContactsService } from '@/services/api.service';

const ShowField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography align="right" variant="body1" color="textSecondary">{title}</Typography>
			<Typography align="right" variant="body1">{value || "-"}</Typography>
		</div>
		)
}

type Contact = {
	phone: string,
	name: string,
	position: string
}

const CustomerContacts = (props: any) => {
	const { secretCode = "" } = useParams();
	const { CustomerRef } = props;
	const [isFetching, setFetching] = useState(true);
	const [isError, setError] = useState(false);
	const [data, setData] = useState([]);
	
	useEffect(() => {
		fetchData(secretCode)
	}, [secretCode])

	const fetchData = async (secret: string) => {
		try{
			const response = await customerContactsService.getList(CustomerRef, { pageIndex: 0, pageSize: 10 }, { secret })
			if(!response.data.ok || !response.data) {
				setError(true);
				return
			}
			setData(response.data.data.rows.filter((row: { phone: string; })=>row.phone!==""))
			setFetching(false)

		}catch(e){
			setFetching(false);
			setError(true);
		}
	}
	
	if(data.length<1) return

	return (
		<Card elevation={0} variant="outlined">
			<CardHeader title="אנשי קשר של לקוחות"/>
			<Divider/>
			<CardContent>
				<List>
					{data.map((contact: Contact,index) => (
						<ListItem
							key={`contact-${index}`}
							secondaryAction={
								<IconButton edge="end" aria-label="comments" href={`tel:${contact?.phone}`}>
									<CallIcon />
								</IconButton>
							}
						>
							<ListItemText
								key={`contact-${index}`}
								primary={contact?.name}
								secondary={contact?.position?`${contact.position}, ${contact.phone}`:contact.phone}
							/>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
		)
}

export default CustomerContacts;