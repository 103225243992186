import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {Grid, Button} from '@mui/material';
import {useSnackbar} from "notistack";

import {deliveryService} from '@/services/api.service';

import InvoicesList from '@/components/DeliveryRoute/InvoicesList';


const DeliveryRouteContainer = () => {
    const { secretCode = "" } = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const [isFetching, setFetching] = useState(true);
    const [isError, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData(secretCode)
    }, [secretCode])

    const fetchData = async (secret: string) => {
        try{
            const response = await deliveryService.getOne({ secret })
            if(!response.data.ok || !response.data) {
                setError(true);
                return
            }
            setData(response.data.data)
            setFetching(false)

        }catch(e){
            setFetching(false);
            setError(true);
        }
    }


    if(isFetching) return (
        <>
        Loading...
        </>
        )
    if(isError) return (
        <>
        <Alert severity="error">משלוח לא נמצא</Alert>
        </>
        )
    return (
        <Grid container>
            <Grid item xs={12}>
                <InvoicesList data={data}/>
            </Grid>
        </Grid>
        )
}

export default DeliveryRouteContainer
