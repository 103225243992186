import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardContent, CardHeader, Divider, List, ListItem, Typography, ListItemText, IconButton} from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';
import { customerLocationsService } from '@/services/api.service';

const ShowField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography align="right" variant="body1" color="textSecondary">{title}</Typography>
			<Typography align="right" variant="body1">{value || "-"}</Typography>
		</div>
		)
}

type Location = {
	name: string,
	country: string,
	city: string,
	street: string,
	houseNumber: string
}

const CustomerLocations = (props: any) => {
	const { secretCode = "" } = useParams();
	const { CustomerRef } = props;
	const [isFetching, setFetching] = useState(true);
	const [isError, setError] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchData(secretCode)
	}, [secretCode])

	const fetchData = async (secret: string) => {
		try{
			const response = await customerLocationsService.getList(CustomerRef, { pageIndex: 0, pageSize: 10 }, { secret: secretCode })
			if(!response.data.ok || !response.data) {
				setError(true);
				return
			}
			setData(response.data.data.rows.filter((row: { phone: string; })=>row.phone!==""))
			setFetching(false)

		}catch(e){
			setFetching(false);
			setError(true);
		}
	}

	if(data.length<1) return

	return (
		<Card elevation={0} variant="outlined">
			<CardHeader title="מיקומי לקוחות"/>
			<Divider/>
			<CardContent>
				<List>
					{data.map((location: Location,index) => (
						<ListItem
							key={`location-${index}`}
							secondaryAction={
	              <IconButton edge="end" aria-label="comments" href={`https://waze.com/ul?ll=32.069359,34.783642&navigate=yes`} target="_blank">
									<NavigationIcon />
								</IconButton>
	            }
						>
							<ListItemText
								primary={location?.name}
								secondary={`${location?.country}, ${location?.city}, ${location?.street}, ${location?.houseNumber}`}
							/>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
		)
}

export default CustomerLocations;