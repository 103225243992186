import {Alert, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography, IconButton, Button, CardActions} from "@mui/material";
import InvoiceViewerModal from '@/components/DeliveryDocument/InvoiceViewerModal';

const ShowField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography align="right" variant="body1" color="textSecondary">{title}</Typography>
			<Typography align="right" variant="body1">{value || "-"}</Typography>
		</div>
		)
}


const InvoiceDetails = (props: any) => {
	const { data, setStatus, onSigned, disabled } = props;
	return (
		<Card elevation={0} variant="outlined">
			<CardHeader
				action={
					data.status === 'scheduled' && <Button variant="contained" color="success" disabled={disabled} onClick={() => setStatus("ontheway")}>On my way</Button>
				}
				title="פרטי חשבונית"
			/>
			<Divider/>
			<CardContent>
				<List>
					<ListItem>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<ShowField title="סטטוס" value={data.status}/>
							</Grid>
							<Grid item xs={6}>
								<ShowField title="מספר חשבונית" value={data.documentId}/>
							</Grid>
							<Grid item xs={12}>
								<ShowField title="לקוחות" value={`${data.Customer.title.he} / ${data.Customer.title.en}`}/>
							</Grid>
							<Grid item xs={12}>
								<ShowField title="הערות משרד אחורי" value={data.documentComment}/>
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</CardContent>
			<CardActions disableSpacing>
				<InvoiceViewerModal data={data} onSigned={onSigned}/>
			</CardActions>
		</Card>
		)
}

export default InvoiceDetails;