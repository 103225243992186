import {useEffect, useState} from 'react';
import {useParams,useNavigate} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {Grid, Button} from '@mui/material';
import {useSnackbar} from "notistack";

import {deliveryService, invoiceService} from '@/services/api.service';

import InvoiceDetails from '@/components/DeliveryDocument/InvoiceDetails';
import CustomerContacts from '@/components/DeliveryDocument/CustomerContacts';
import CustomerLocations from '@/components/DeliveryDocument/CustomerLocations';


const DeliveryDocumentContainer = () => {
    const { secretCode = "", InvoiceRef = "" } = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [isFetching, setFetching] = useState(true);
    const [isError, setError] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        fetchData(secretCode)
    }, [secretCode])

    const fetchData = async (secret: string) => {
        try{
            const response = await deliveryService.getOne({ secret })
            if(!response.data.ok || !response.data) {
                setError(true);
                return
            }
            setData(response.data.data)
            setFetching(false)

        }catch(e){
            setFetching(false);
            setError(true);
        }
    }

    const setStatus = async (newStatus: string) => {
        setFetching(true);
        const response = await invoiceService.update(InvoiceRef, { status: newStatus }, { secret: secretCode });
        fetchData(secretCode);
        enqueueSnackbar("Status has been updated", {variant: 'success'})
    }


    const invoice = data?.Invoices.filter((invoice: { _id: string; })=>invoice._id===InvoiceRef)[0];

    if(isFetching) return <>Loading...</>
    if(isError) return <Alert severity="error">משלוח לא נמצא</Alert>

    return (
        <Grid container>
            <Grid item xs={12}>
                <InvoiceDetails disabled={data?.status!='ontheway'} data={invoice} setStatus={setStatus} onSigned={fetchData}/>
            </Grid>
            <Grid item xs={12}>
                <CustomerContacts CustomerRef={invoice?.Customer._id} />
            </Grid>
            <Grid item xs={12}>
                <CustomerLocations CustomerRef={invoice?.Customer._id} />
            </Grid>
        </Grid>
        )
}

export default DeliveryDocumentContainer
