import axios from "../utils/axios";

type apiServiceParams = {
    secret: string
}

type paginationPayload = {
    pageIndex: number,
    pageSize: number,
}

const configGenerator = (params: apiServiceParams) => {
    const { secret } = params
    return {
        headers: {
            "x-collectease-delivery-id": secret,
            "x-collectease-org-id": "org_2SGX05GbRsl2qdu6Oa5BGPJUx1n", //"org_2SFJplwgZQWRN2mgkdIInWloy1g",
            "x-collectease-courier-id": "65d620a90b275e2bf947a37a", //"65b947b6ed01934824d131f4",
            "x-collectease-delivery-app-v": "v0.0.1"
        },
    }
}

const configUploadGenerator = (params: apiServiceParams) => {
    const { secret } = params
    return {
        headers: {
            "x-collectease-delivery-id": secret,
            "x-collectease-org-id": "org_2SFJplwgZQWRN2mgkdIInWloy1g",
            "x-collectease-courier-id": "65b947b6ed01934824d131f4",
            "x-collectease-delivery-app-v": "v0.0.1"
        },
    };
};


class CustomerContactsService {
    getList = (CustomerRef: string, payload: paginationPayload, params: apiServiceParams) => axios.get(`/v1/deliveryapp/customers/${CustomerRef}/contacts?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
}

class CustomerLocationsService {
    getList = (CustomerRef: string, payload: paginationPayload, params: apiServiceParams) => axios.get(`/v1/deliveryapp/customers/${CustomerRef}/locations?page=${payload.pageIndex}&pageSize=${payload.pageSize}`, configGenerator(params))
}

class DeliveryService {
    getOne = (params: apiServiceParams) => axios.get(`/v1/deliveryapp/delivery`, configGenerator(params))
    update = (params: apiServiceParams, payload: any) => axios.post(`/v1/deliveryapp/delivery`, payload, configGenerator(params))
}

class InvoiceService {
    getLink = (InvoiceRef: string, params: apiServiceParams) => axios.get(`/v1/deliveryapp/invoices/${InvoiceRef}/getLink`, configGenerator(params))
    update = (InvoiceRef: string, payload: any, params: apiServiceParams, ) => axios.post(`/v1/deliveryapp/invoices/${InvoiceRef}`, payload, configGenerator(params));
    sign = (InvoiceRef: string, payload: any, params: apiServiceParams, ) => axios.post(`/v1/deliveryapp/invoices/${InvoiceRef}/sign`, payload, configGenerator(params));
}

export const deliveryService = new DeliveryService()
export const invoiceService = new InvoiceService()
export const customerContactsService = new CustomerContactsService();
export const customerLocationsService = new CustomerLocationsService();

