import * as React from 'react';
import {useNavigate,useLocation, useParams} from 'react-router-dom';
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const BottomMenu = () => {
  const [value, setValue] = React.useState(0);
	const { secretCode } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const currentRoute = location.pathname;

	React.useEffect(() => {
		if (currentRoute.includes('/help')) {
			setValue(2);
		} else if (currentRoute.includes('/route')) {
			setValue(1);
		} else if (currentRoute.includes('/view')) {
			setValue(0);
		} else {
			setValue(0);
		}
	}, [currentRoute]);


  return (
			<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
	      <BottomNavigation
	        showLabels
	        value={value}
	      >
					<BottomNavigationAction label="מידע" icon={<InfoIcon />} onClick={() => navigate(`/delivery/${secretCode}/view`)}/>
					<BottomNavigationAction label="מסלול משלוח" icon={<LocationOnIcon />} onClick={() => navigate(`/delivery/${secretCode}/route`)}/>
					<BottomNavigationAction label="שאלות נפוצות" icon={<HelpIcon />} onClick={() => navigate(`/delivery/${secretCode}/help`)}/>
				</BottomNavigation>
			</Paper>
		);
}

export default BottomMenu;