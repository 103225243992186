import {Alert, Card, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import {useParams,useNavigate} from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


const ShowField = (props: any) => {
	const {title, value} = props;
	return (
		<div>
			<Typography align="right" variant="body1" color="textSecondary">{title}</Typography>
			<Typography align="right" variant="body1">{value || "-"}</Typography>
		</div>
		)
}


const DeliveryDetails = (props: any) => {
	const { secretCode } = useParams();
	const navigate = useNavigate();
	const { data } = props;
	const { Invoices } = data;
	return (
		<Card elevation={0} variant="outlined">
			<CardHeader title="מסלול משלוח"/>
			<Divider/>
			<CardContent>
				<List>
					{Invoices.map((invoice: any, index: number) => (
						<ListItem disablePadding key={`list-delivery-${index}`} onClick={() => navigate(`/delivery/${secretCode}/invoice/${invoice._id}`)}>
							<ListItemButton>
								<ListItemIcon>
									{invoice.status == 'scheduled' && <RadioButtonUncheckedIcon/>}
									{invoice.status == 'ontheway' && <LocalShippingIcon/>}
									{invoice.status == 'delivered' && <CheckCircleIcon/>}
								</ListItemIcon>
								<ListItemText primary={`${invoice.Customer.title.he} / ${invoice.Customer.title.en}`} secondary={`${invoice.documentId} | ${invoice.documentComment}`} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
		)
}

export default DeliveryDetails;