import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {Grid, Button} from '@mui/material';
import {useSnackbar} from "notistack";

import {deliveryService} from '@/services/api.service';

import DeliveryDetails from '@/components/Delivery/DeliveryDetails';

const DeliveryDetailsContainer = () => {
    const { secretCode = "" } = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const [isFetching, setFetching] = useState(true);
    const [isError, setError] = useState(false);
    const [data, setData] = useState({status: null, Invoices: []});

    useEffect(() => {
        fetchData(secretCode)
    }, [secretCode])

    const fetchData = async (secret: string) => {
        try{
            const response = await deliveryService.getOne({ secret })
            if(!response.data.ok || !response.data) {
                setError(true);
                return
            }
            setData(response.data.data)
            setFetching(false)

        }catch(e){
            setFetching(false);
            setError(true);
        }
    }


    const setStatus = async (newStatus: string) => {
        setFetching(true);
        const response = await deliveryService.update({ secret: secretCode }, { status: newStatus });
        fetchData(secretCode);
        enqueueSnackbar("Status has been updated", {variant: 'success'})
    }

    // @ts-ignore
	const undeliveredInvoices = data?.Invoices.filter((invoice: { status: string; }) => invoice.status!=="delivered").length
    
    if(isFetching) return (
        <>
            Loading...
        </>
    )
    if(isError) return (
        <>
            <Alert severity="error">משלוח לא נמצא</Alert>
        </>
    )
	return (
        <> 
            <Grid container>
                <Grid item xs={12}>
                    <DeliveryDetails data={data}/>
                </Grid>
                <Grid item xs={12}><br/><br/></Grid>
                <Grid item xs={12}>
                    {data?.status == "created" && <Button variant="contained" size="large" color="success" onClick={() => setStatus("ontheway")}>Start delivery</Button>}
                    {data?.status == "ontheway" && (
                    <>
                        {undeliveredInvoices > 0 ? (<Alert severity="warning">You cannot finish delivery until not all deliveries completed</Alert>) : (<Button variant="contained" size="large" color="success" onClick={() => setStatus("delivered")}>Finish delivery</Button>)}
                    </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default DeliveryDetailsContainer
